import React from "react"
import { graphql } from "gatsby"
// import SEO from "../components/SEO"
import Layout from '../layouts/Layout'
import SubpageHeader from '../components/SubpageHeader'
import Lightbox from '../components/Lightbox'

export const query = graphql`
    {
        prismic {
            allDiarys {
                edges {
                    node {
                        diary_description
                        photos {
                            photo
                            photoSharp {
                                childImageSharp {
                                    fluid(maxWidth: 760, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allAbouts {
                edges {
                    node {
                    short_footer_text
                    }
                }
            }
        }
    }
`

const Diary = ({ data, location }) => {
    const footerData = data.prismic.allAbouts.edges.slice(0, 1).pop()
    const diaryData = data.prismic.allDiarys.edges.slice(0, 1).pop()
    if (!diaryData || !footerData) return null

    return (
        <>
            {/* <SEO title="Projects" /> */}
            <Layout
                location={location}
                footerShortText={footerData.node.short_footer_text[0].text}
                footerLinksArray={[      
                    {
                        name: 'Home',
                        URL: '/'
                    },
                    {
                        name: 'Projects',
                        URL: '/projects/'
                    },
                    {
                        name: 'Events',
                        URL: '/events/'
                    },
                ]}>
                <SubpageHeader headline={"DIARY"} introTextArray={diaryData.node.diary_description}></SubpageHeader>
                <Lightbox 
                    imageArray={diaryData.node.photos}
                    galleryColumns={3}
                    ></Lightbox>
            </Layout>
        </>
    )
}

export default Diary
